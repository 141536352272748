
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        

































































































.basket-popup,
[class*='basket-popup--'] {
  max-height: 80vh;
  padding: $spacing * 2;

  &.generic-popup {
    display: block;
    overflow: auto;
    width: calc(100vw - 4rem);
    max-width: 64rem;
    background: $white;
    border-radius: $border-radius;
  }
}

.basket-popup__title {
  margin-top: $spacing * 0.5;
}

.basket-popup__description {
  margin-top: 1.5rem;
  margin-bottom: $spacing * 2.5;
}

.basket-popup__icon {
  width: 4.4rem;
  height: 4rem;
  fill: $c-pink-medium;
}
